import { GraphCustomizerState } from '../hooks/use-graph-style-customisation';
import { ClusterId } from './cluster';
import { EdgeId, EdgeInfo, EdgeStyle } from './edge';
import { UniverseEdgeTypeName, UniverseVertexTypeName } from './universe';
import { InfoTitle, VertexId, VertexInfo, VertexStyle } from './vertex';
import { VertexFilter } from './filter';
import { ExplorationGroup, ExplorationGroupId } from './group';

export const EMPTY_VISUALIZATION: Visualization = {
    clusterEdges: {},
    clusters: {},
    filteredVerticesCount: 0,
    edges: {},
    groups: {},
    selectedEdgesCount: 0,
    selectedFlaggedObjectsCount: 0,
    selectedVerticesCount: 0,
    verticesCount: 0,
    vertices: {},
    preselectedCount: 0,
};

interface PropertyParameters {
    canBeDefinedAsTitle: boolean;
}

export interface VertexPropertyParameters extends PropertyParameters {
    isClusterable: boolean;
}

type EdgePropertyParameters = PropertyParameters;

export interface VertexStyleProperties {
    properties: Record<string, VertexPropertyParameters>;
}

interface EdgeStylesProperties {
    properties: Record<string, EdgePropertyParameters>;
}

export type ConfigurableVertices = Record<string, VertexStyleProperties>;

export type ConfigurableEdges = Record<string, EdgeStylesProperties>;

export interface ExplorationVisualizationStyleCapabilities {
    configurableVertices: ConfigurableVertices;
    configurableEdges: ConfigurableEdges;
}

export interface ExplorationVisualizationStyles {
    id?: string;
    templateId?: string;
    vertexStyles: Record<UniverseVertexTypeName, GraphCustomizerState>;
    edgeStyles: Record<UniverseEdgeTypeName, GraphCustomizerState>;
}

export interface VisualizationGroup extends ExplorationGroup {
    count: number;
}

export interface Visualization {
    verticesCount: number;
    selectedVerticesCount: number;

    filteredVerticesCount: number;

    clusters: Record<ClusterId, VisualizationCluster>;
    vertices: Record<VertexId, VisualizationVertex>;
    edges: Record<EdgeId, VisualizationEdge>;
    clusterEdges: Record<EdgeId, VisualizationClusterEdge>;
    groups: Record<ExplorationGroupId, VisualizationGroup>;

    //edgesCount: number;
    selectedEdgesCount: number;

    selectedFlaggedObjectsCount: number;

    preselectedCount: number;
}

export interface VisualizationCluster {
    id: ClusterId;
    count: number;
    selectedCount: number;
    style?: VertexStyle;
    filter: VertexFilter;
    preselectedCount?: number;
}

export interface VisualizationEdge extends EdgeInfo {
    isSelected?: boolean;
    style?: EdgeStyle;
    groupSize?: number;
    isPreSelected?: boolean;
}

export enum EndpointType {
    Cluster = 'Cluster',
    Vertex = 'Vertex',
}

export interface VisualizationEdgeEndpoint {
    id: ClusterId | VertexId;
    type: EndpointType;
}

export interface VisualizationClusterEdge {
    id: EdgeId;
    style?: EdgeStyle;

    title?: InfoTitle;
    count?: number;
    from: VisualizationEdgeEndpoint;
    to: VisualizationEdgeEndpoint;

    isSelected?: boolean;
    isPreselected?: boolean;
    type: UniverseEdgeTypeName;
}

export interface CaseFlag {
    label?: string;
}

export interface VisualizationVertex extends VertexInfo {
    isSelected?: boolean;
    style?: VertexStyle;
    groupIds?: ExplorationGroupId[];
    score?: number;
    isExtracted?: boolean;
    isPreselected?: boolean;
}

export interface VisualizationAttachment {
    id: VertexId;
    isFavorite: boolean;
}
