import { useCallback, useState, useMemo } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { ProgressMonitor, useArgNotifications, useCallbackAsync } from 'src/components/basic';
import ReferenceTablesConnector from 'src/settings/connectors/reference-tables-connector';
import { Item } from 'src/settings/models/reference-tables';

const messages = defineMessages({
    createReferenceTableError: {
        id: 'settings.reference-tables.use-create-reference-table.CreateReferenceTableError',
        defaultMessage: 'Failed to create reference table "{referenceTableName}"',
    },
    invalidTableNameError: {
        id: 'settings.reference-tables.use-create-reference-table.InvalidTableNameError',
        defaultMessage: 'Reference table name is required',
    },
    invalidRowsError: {
        id: 'settings.reference-tables.use-create-reference-table.InvalidRowsError',
        defaultMessage: 'At least one valid row is required',
    },
});

interface Params {
    initialName?: string;
    initialRows?: Item[];
    onCreate?: () => void;
    onError?: (error: Error) => void;
}

interface ValidationResult {
    isValid: boolean;
    errors: MessageDescriptor[];
}

interface UseCreateReferenceTableResult {
    tableName: string;
    setTableName: (name: string) => void;
    tableDescription: string;
    setTableDescription: (description: string) => void;
    rows: Item[];
    addRow: () => void;
    updateRow: (index: number, field: keyof Item, value: string) => void;
    deleteRow: (index: number) => void;
    createReferenceTable: () => Promise<void>;
    createReferenceTableProgressMonitor?: ProgressMonitor; // Replace 'any' with the actual type from useCallbackAsync
    isValid: boolean;
    errors: MessageDescriptor[];
}

const isRowValid = (row: Item) => row.key.trim() !== '' && row.value.trim() !== '';

export function useCreateReferenceTable(params: Params = {}): UseCreateReferenceTableResult {
    const notifications = useArgNotifications();

    const { initialName = '', initialRows = [], onCreate, onError } = params;

    const [tableName, setTableName] = useState(initialName);
    const [tableDescription, setTableDescription] = useState('');
    const [rows, setRows] = useState<Item[]>(initialRows);

    const addRow = useCallback(() => {
        setRows(prev => [...prev, { key: '', value: '' }]);
    }, []);

    const updateRow = useCallback((index: number, field: keyof Item, value: string) => {
        setRows(prev =>
            prev.map((item, i) => (i === index ? { ...item, [field]: value } : item)),
        );
    }, []);

    const deleteRow = useCallback((index: number) => {
        setRows(prev => prev.filter((_, i) => i !== index));
    }, []);

    const validationResult = useMemo((): ValidationResult => {
        const errors: MessageDescriptor[] = [];
        if (!tableName.trim()) {
            errors.push(messages.invalidTableNameError);
        }
        if (!rows.some(isRowValid)) {
            errors.push(messages.invalidRowsError);
        }

        return {
            isValid: errors.length === 0,
            errors,
        };
    }, [tableName, rows]);

    const [createReferenceTable, createReferenceTableProgressMonitor] = useCallbackAsync(async (progressMonitor) => {
        try {
            if (!validationResult.isValid) {
                throw new Error(validationResult.errors.join(', '));
            }

            await ReferenceTablesConnector().createReferenceTable({ name: tableName, items: rows, description: tableDescription }, progressMonitor);
            onCreate?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            const errorMessage = error instanceof Error ? error.message : String(error);
            notifications.snackError(
                { message: messages.createReferenceTableError },
                new Error(errorMessage),
                { referenceTableName: tableName },
            );
            onError?.(error instanceof Error ? error : new Error(errorMessage));
            throw error;
        }
    }, [notifications, onCreate, onError, rows, tableDescription, tableName, validationResult.errors, validationResult.isValid]);

    return {
        tableName,
        setTableName,
        rows,
        tableDescription,
        setTableDescription,
        addRow,
        updateRow,
        deleteRow,
        createReferenceTable,
        createReferenceTableProgressMonitor,
        isValid: validationResult.isValid,
        errors: validationResult.errors,
    };
}
