import React from 'react';
import ReactDOM from 'react-dom';
import Dayjs from 'dayjs';
import { chain, compact, forEach, map } from 'lodash';
import Debug from 'debug';
import * as ReactRouter from 'react-router';
import * as ReactRouterDom from 'react-router-dom';
import * as ReactIntl from 'react-intl';
import ReactJsxRuntime from 'react/jsx-runtime';
import { defineMessages } from 'react-intl';

import { EventEmitter, ProgressMonitor, setImmutable, SubProgressMonitor } from 'src/components/basic';
import { ArgonosModule } from 'src/components/application/modules';
import { ExtensionsConnector } from './extensions-connector';
import { ArgonosExtensionComponentTypeDescriptor, ExtensionComponent, ExtensionDescriptor, ExtensionId, ExtensionItem, ExtensionRuntime, ExtensionsList } from './models';

import productPackageJson from '../../../package.json';

const debug = Debug('framework:extensions:ExtensionsRegistry');

const NO_EXTENSION_HASH = false;

const NO_MESSAGES:Record<string, string> = setImmutable({});

const messages = defineMessages({
    unknownArgonosExtensionComponentType: {
        id: 'framework.extensions.extensions-registry.UnknownArgonosExtensionComponentType',
        defaultMessage: 'Unknown type',
    },
});

interface InternalExtensionRuntime {
    runtime: ExtensionRuntime;

    module: Record<string, unknown>;
    exports: Record<string, unknown>;
    required: ExtensionId[];

    locales: Record<string, Record<string, string>>;

    dependencyRuntimes: Record<ExtensionId, InternalExtensionRuntime>;

    waitingPromises?: {resolve: (value: InternalExtensionRuntime | PromiseLike<InternalExtensionRuntime>)=>void; reject: (error: Error)=>void; progressMonitor?: ProgressMonitor}[];
}

interface LoadingContext {
    // To check cycles
    loadingExtensions: Record<ExtensionId, true>;
}

export interface ExtensionRegisterEvent<C extends ExtensionComponent> {
    extensionRuntime: ExtensionRuntime;
    component: C;

    setProcessed: ()=>void;
}

/**
 * A repository for handling extensions in Argonos.
 */
export class ExtensionsRegistry {
    private static instance: ExtensionsRegistry;

    readonly #extensionEventEmitter:EventEmitter;

    readonly #extensionLoadedById:Record<string, ExtensionDescriptor|'loading'> = {};

    readonly #internalExtensionRuntimesById: Record<string, InternalExtensionRuntime> = {};
    readonly #argonosExtensionComponentTypes: Record<string, ArgonosExtensionComponentTypeDescriptor> = {};

    #hasDeveloperExtensions = false;

    static getInstance(): ExtensionsRegistry {
        if (!ExtensionsRegistry.instance) {
            ExtensionsRegistry.instance = new ExtensionsRegistry();
        }

        return ExtensionsRegistry.instance;
    }

    constructor() {
        this.#extensionEventEmitter = new EventEmitter();
    }

    get hasDeveloperExtensions():boolean {
        return this.#hasDeveloperExtensions;
    }

    async getLocaleMessages(userLocale: string, progressMonitor: ProgressMonitor): Promise<Record<string, string>> {
        debug('getLocaleMessages', 'Load locale messages', userLocale);

        const runtimes = Object.values(this.#internalExtensionRuntimesById);

        const ps = runtimes.map(async (runtime: InternalExtensionRuntime):Promise<Record<string, string>|undefined> => {
            const result = runtime.locales?.[userLocale];
            if (result) {
                return result;
            }

            const sub = new SubProgressMonitor(progressMonitor, 1);

            let messages = await this.#loadLocaleMessage(runtime, userLocale, sub);
            if (!messages || messages === NO_MESSAGES) {
                const ref = /^([A-Z0-9]+)[-_]([A-Z0-9]+)/i.exec(userLocale);
                if (ref) {
                    const reducedUserLocale = ref[1];
                    const result = runtime.locales?.[reducedUserLocale];
                    if (result) {
                        runtime.locales[userLocale] = result;

                        return result;
                    }

                    messages = await this.#loadLocaleMessage(runtime, reducedUserLocale, sub);
                    runtime.locales[userLocale] = messages;
                }
            }

            return messages;
        });

        const messages = await Promise.all(ps);

        const result = Object.assign({}, ...messages);

        return result;
    }

    async #loadLocaleMessage(runtime: InternalExtensionRuntime, userLocale: string, progressMonitor: ProgressMonitor): Promise<Record<string, string>> {
        if (!runtime.locales) {
            runtime.locales = {};
        }

        const localePath = runtime.runtime.descriptor.locales?.[userLocale]
            || runtime.runtime.descriptor.locales?.['*'];

        if (!localePath) {
            runtime.locales[userLocale] = NO_MESSAGES;

            return NO_MESSAGES;
        }

        const localeURL = new URL(localePath, runtime.runtime.descriptor.baseURL).toString();

        const messages = await ExtensionsConnector.getInstance().loadExtensionLocale(
            localeURL,
            runtime.runtime.descriptor.argonosModule,
            progressMonitor,
        );

        runtime.locales[userLocale] = messages || NO_MESSAGES;

        return messages || NO_MESSAGES;
    }

    private isExtensionFeatureDisabled(): boolean {
        const querySearch = new URLSearchParams(window.location.search);

        return localStorage.ENABLE_EXTENSION_FEATURE === 'false' || querySearch.has('--arg-no-extensions');
    }

    private async loadDevelopmentExtensions(argonosModule: ArgonosModule, progressMonitor: ProgressMonitor): Promise<void> {
        const developerExtensionsBaseURL = localStorage.getItem(`ARG_DEVELOPER_EXTENSIONS:${argonosModule.id}`);
        if (!developerExtensionsBaseURL || developerExtensionsBaseURL === 'false') {
            debug('Extension Development feature is disabled or not configured!');

            return;
        }

        debug('Loading developer extensions for module', argonosModule.id, 'from URL:', developerExtensionsBaseURL);

        const baseURL = developerExtensionsBaseURL.endsWith('/') ? developerExtensionsBaseURL : `${developerExtensionsBaseURL}/`;
        const extensionListURL = `${baseURL}list.json`;
        const extensionsList = await ExtensionsConnector.getInstance().loadExtensionsFromList(extensionListURL, baseURL, argonosModule, progressMonitor);

        await this._loadExtensionManifests(extensionsList, argonosModule, progressMonitor);
    }

    private async loadDeployedExtensions(argonosModule: ArgonosModule, progressMonitor: ProgressMonitor): Promise<void> {
        debug('Loading deployed extensions for module', argonosModule.id);

        const extensionsList = await ExtensionsConnector.getInstance().loadExtensions(argonosModule, progressMonitor);
        await this._loadExtensionManifests(extensionsList, argonosModule, progressMonitor);
    }

    /**
     * Load the extensions for the given Argonos module.
     * If the extension feature is not enabled or if the '--arg-no-extensions' flag is present in the query string,
     * this method will return without doing anything.
     * Otherwise, it will load the extensions for the Argonos module using the ExtensionsConnector.
     *
     * @param {ArgonosModule} argonosModule - The Argonos module for which to load the extensions.
     * @param {ProgressMonitor} progressMonitor - The progress monitor to use for tracking the loading progress.
     * @return {Promise<void>} - A promise that resolves once the extensions have been loaded.
     */
    async loadArgonosModuleExtensions(argonosModule: ArgonosModule, progressMonitor: ProgressMonitor): Promise<void> {
        if (this.isExtensionFeatureDisabled()) {
            debug('Extension feature is disabled. Skipping extension loading.');

            return;
        }

        // Load development and deployed extensions concurrently
        // Using Promise.allSettled ensures that if one type of extension fails to load,
        // it doesn't prevent the other type from loading
        const results = await Promise.allSettled([
            this.loadDevelopmentExtensions(argonosModule, progressMonitor),
            this.loadDeployedExtensions(argonosModule, progressMonitor),
        ]);

        results.forEach((result, index) => {
            if (result.status === 'rejected') {
                // Used argonosModule.id since argonosModule.name is localized (MessageDescriptor)
                console.error(`Error loading ${index === 0 ? 'development' : 'deployed'} extensions for module ${argonosModule.id}:`, result.reason);
            } else {
                debug(`Successfully loaded ${index === 0 ? 'development' : 'deployed'} extensions for module ${argonosModule.id}`);
            }
        });

        debug('Completed loadArgonosModuleExtensions for module:', argonosModule.id);
    }

    /**
     * @private
     */
    async _loadExtensionManifests(extensionsList: ExtensionsList, argonosModule: ArgonosModule, progressMonitor: ProgressMonitor): Promise<void> {
        const extensionPromises = chain(extensionsList.extensions)
            .filter((extensionItem) => (!this.#extensionLoadedById[extensionItem.name]))
            .map(async (extensionItem: ExtensionItem) => {
                this.#extensionLoadedById[extensionItem.name] = 'loading';

                debug('_loadExtensions', 'loading extension', extensionItem);

                const sub = new SubProgressMonitor(progressMonitor, 1);

                const extensionURL = new URL(`${encodeURIComponent(extensionItem.name)}/`, extensionsList.extensionsBaseURL);

                const manifestPath = (extensionItem.contentHash && !NO_EXTENSION_HASH)
                    ? `static/${encodeURIComponent(extensionItem.contentHash)}/manifest.json`
                    : 'public/manifest.json';
                const manifestURL = new URL(manifestPath, extensionURL).toString();

                debug('_loadExtensions', 'loading extension', extensionItem, 'manifestPath=', manifestURL, 'extensionURL=', extensionURL, 'argonosModule=', argonosModule.id);

                try {
                    const extensionDescriptor = await ExtensionsConnector.getInstance().loadExtensionManifest(
                        manifestURL,
                        argonosModule,
                        extensionItem.name,
                        sub,
                    );

                    debug('_loadExtensions', 'loading extension', extensionItem, 'loaded', 'descriptor=', extensionDescriptor);

                    this.#extensionLoadedById[extensionItem.name] = extensionDescriptor;

                    return extensionDescriptor;
                } catch (error) {
                    console.error(error);

                    return undefined;
                }
            })
            .compact()
            .value();

        const extensionDescriptors = compact(await Promise.all(extensionPromises));

        debug('_loadExtensions', extensionDescriptors.length, 'manifest loaded');

        const result = extensionDescriptors.map(async (extensionDescriptor: ExtensionDescriptor): Promise<void> => {
            if (extensionDescriptor.loading === 'startup') {
                debug('_loadExtensions', 'loading at startup for extension', extensionDescriptor.name);

                try {
                    await this.loadExtension(extensionDescriptor);
                } catch (error) {
                    console.error('Can not load extension at startup', 'error=', error);
                }
            }

            debug('_loadExtensions', 'Send onLoaded event for extension', extensionDescriptor.name);

            this.#extensionEventEmitter.emit('onManifestLoaded', extensionDescriptor);
        });

        await Promise.allSettled(result);

        debug('_loadExtensions', extensionDescriptors.length, 'initialized');
    }

    /**
     * Asynchronously loads an extension. (Execute JS code of extension and install extension points)
     *
     * @param {ExtensionDescriptor} extensionDescriptor - The descriptor of the extension to load.
     * @param {ProgressMonitor} [progressMonitor=ProgressMonitor.empty()] - The progress monitor to track the loading progress. Defaults to an empty progress monitor.
     * @return {Promise<ExtensionRuntime>} - A promise that resolves to the runtime of the loaded extension.
     */
    async loadExtension(extensionDescriptor: ExtensionDescriptor, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ExtensionRuntime> {
        debug('loadExtension', 'extension=', extensionDescriptor.name);

        const result = await this._loadExtension(extensionDescriptor, undefined, progressMonitor);

        return result.runtime;
    }

    /**
     * @private
     */
    async _loadExtension(
        extensionDescriptor: ExtensionDescriptor,
        context: LoadingContext = { loadingExtensions: {} },
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<InternalExtensionRuntime> {
        const extensionKey = extensionDescriptor.name + (extensionDescriptor.version ? (`;${extensionDescriptor.version}`) : '');

        let internalExtensionRuntime: InternalExtensionRuntime = this.#internalExtensionRuntimesById[extensionKey];

        debug('_loadExtension', 'extensionKey=', extensionKey, 'internalExtensionRuntime=', internalExtensionRuntime);
        if (internalExtensionRuntime) {
            if (internalExtensionRuntime.waitingPromises) {
                const promise = new Promise<InternalExtensionRuntime>((resolve, reject) => {
                    internalExtensionRuntime.waitingPromises!.push({ resolve, reject, progressMonitor });
                });

                return promise;
            }

            // Already loaded
            return internalExtensionRuntime;
        }

        internalExtensionRuntime = {
            runtime: {
                descriptor: extensionDescriptor,
                status: 'Initializing',
            },
            module: {},
            exports: {},
            required: [],
            waitingPromises: [],
            dependencyRuntimes: {},
            locales: {},
        };

        this.#internalExtensionRuntimesById[extensionKey] = internalExtensionRuntime;

        if (extensionDescriptor.dependencies) {
            const dependencyPromises: Promise<InternalExtensionRuntime | null>[] = map(extensionDescriptor.dependencies, async (dependencyVersion: string, dependencyExtensionId: string) => {
                if (dependencyExtensionId === productPackageJson.name) {
                    return null;
                }

                const dependencyExtensionDescriptor = this.#extensionLoadedById[dependencyExtensionId];
                if (!dependencyExtensionDescriptor) {
                    console.error('Unknown dependency', dependencyExtensionId);

                    return null;
                }
                if (dependencyExtensionDescriptor === 'loading') {
                    console.error('INTERNAL ERROR');

                    return null;
                }

                if (context.loadingExtensions[extensionKey]) {
                    throw new Error('Dependency cycle detected');
                }
                context.loadingExtensions[extensionKey] = true;

                const sub = new SubProgressMonitor(progressMonitor, 1);

                const dependencyInternalRuntime = await this._loadExtension(
                    dependencyExtensionDescriptor,
                    context,
                    sub,
                );

                return dependencyInternalRuntime;
            });

            const dr = await Promise.all(dependencyPromises);
            dr.forEach((dependencyRuntime: InternalExtensionRuntime | null) => {
                if (!dependencyRuntime) {
                    return;
                }
                internalExtensionRuntime.dependencyRuntimes[dependencyRuntime.runtime.descriptor.name] = dependencyRuntime;
            });
        }

        try {
            await this._loadExtensionRuntime(internalExtensionRuntime);

            debug('Load of', extensionDescriptor.name, 'succeed');
        } catch (error) {
            console.error(error);

            const waitingPromises = internalExtensionRuntime.waitingPromises;
            internalExtensionRuntime.waitingPromises = undefined;

            waitingPromises?.forEach(({ reject }) => {
                reject(error as Error);
            });

            throw error;
        }

        const waitingPromises = internalExtensionRuntime.waitingPromises;
        internalExtensionRuntime.waitingPromises = undefined;

        waitingPromises?.forEach(({ resolve }) => {
            resolve(internalExtensionRuntime);
        });

        return internalExtensionRuntime;
    }

    /**
     * @private
     */
    async _loadExtensionRuntime(internalExtensionRuntime: InternalExtensionRuntime): Promise<void> {
        const extensionRuntime = internalExtensionRuntime.runtime;
        const { descriptor } = extensionRuntime;
        const mainFilename = descriptor.main || './index.js';

        const mainURL = new URL(mainFilename, descriptor.baseURL).toString();

        debug('_loadExtensionRuntime', 'Loading extension runtime of', descriptor.name, 'url=', mainURL);
        extensionRuntime.loadingDate = new Date();

        const mainSource = await ExtensionsConnector.getInstance().loadExtensionMain(mainURL, descriptor.argonosModule);

        extensionRuntime.loadedDate = new Date();

        const globals: Record<string, any> = {
            react: React,
            'react/jsx-runtime': ReactJsxRuntime,
            'react-dom': ReactDOM,
            'react-router': ReactRouter,
            'react-router-dom': ReactRouterDom,
            'react-intl': ReactIntl,
            dayjs: Dayjs,
            ...internalExtensionRuntime.runtime.descriptor.argonosModule?.extensionGlobals,

        };

        const extensionExports = {};
        const extensionModule: { 'exports'?: any } = {};

        internalExtensionRuntime.exports = extensionExports;
        internalExtensionRuntime.module = extensionModule;
        internalExtensionRuntime.required = [];

        const extensionRequire = (name: ExtensionId) => {
            const extensionDependency = internalExtensionRuntime.dependencyRuntimes?.[name];
            if (extensionDependency) {
                internalExtensionRuntime.required.push(name);

                return extensionDependency.exports;
            }

            const globalDependency = globals[name];
            if (!globalDependency) {
                throw new Error(`Extension ${descriptor.name} requires an unknown dependency "${name}".`);
            }

            internalExtensionRuntime.required.push(name);

            return globalDependency;
        };

        debug('_loadExtensionRuntime', 'Load source of extension', descriptor.name);
        let umdFunction;
        try {
            umdFunction = new Function('exports', 'module', 'require', mainSource);
        } catch (x) {
            console.error(`Parse error for extension=${descriptor.name}`, x);

            const error = new Error(`Can not parse source of extension: ${descriptor.name}`);
            (error as any).reason = x;

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        debug('_loadExtensionRuntime', 'Source loaded, execute source to get entry point', descriptor.name);

        let extensionEntryPoint: ExtensionComponent[];
        try {
            extensionEntryPoint = umdFunction(extensionExports, extensionModule, extensionRequire);
            extensionEntryPoint = extensionModule['exports'];
        } catch (x) {
            console.error(`Setup error for extension=${descriptor.name}`, x);

            const error = new Error(`Can not evaluate source of plugin: ${descriptor.name}`);
            (error as any).reason = x;

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        debug('_loadExtensionRuntime', 'Plugin executed', 'extensionModule', extensionModule, 'extensionExports=', extensionExports, 'return=', extensionEntryPoint);

        if (!extensionEntryPoint) {
            const error = new Error(`Invalid plugin ${descriptor.name} package`);

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        extensionEntryPoint = (extensionEntryPoint).filter(component => {
            return internalExtensionRuntime.runtime.descriptor.components?.find(extensionPoint => extensionPoint.id === component.id);
        });

        const register = (component: ExtensionComponent) => {
            let processed = false;

            // debug('_loadExtensionRuntime', 'Register', 'type=', type, 'props=', component, 'extension=', descriptor.name);

            const event: ExtensionRegisterEvent<ExtensionComponent> = {
                extensionRuntime,
                component,
                setProcessed() {
                    processed = true;
                },
            };

            this.#extensionEventEmitter.emit(`onSetupComponent:${component.type}`, event);

            if (!processed) {
                console.error(`Unsupported register of type '${component.type}' for extension ${descriptor.name}`);
            }
        };


        try {
            extensionEntryPoint.forEach(register);

            extensionRuntime.status = 'Ready';
            extensionRuntime.readyDate = new Date();
        } catch (x) {
            const error = new Error(`Evaluation error for plugin: ${descriptor.name}: ${(x as Error).message}`);
            (error as any).reason = x;

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        this.#extensionEventEmitter.emit('onExtensionCompletelyLoaded', extensionRuntime);

        debug('_loadExtensionRuntime', 'Extension runtime loaded', 'extension=', descriptor.name);
    }

    /**
     * Called for every extension point defined in the extension's manifest
     *
     * @param extensionPointType
     * @param handler
     */
    onManifestExtensionPointDeclaration(
        handler: (extensionDescriptor: ExtensionDescriptor, extensionPointDescriptor: ExtensionComponent)=>void,
    ): ()=>void {
        const h = (extensionDescriptor: ExtensionDescriptor) => {
            extensionDescriptor.components?.forEach((extensionPoint) => {
                handler(extensionDescriptor, extensionPoint);
            });
        };

        this.#extensionEventEmitter.on('onManifestLoaded', h);

        forEach(this.#extensionLoadedById, (extensionDescriptor: ExtensionDescriptor|'loading') => {
            if (extensionDescriptor === 'loading') {
                return;
            }

            h(extensionDescriptor);
        });


        return () => {
            this.#extensionEventEmitter.off('onManifestLoaded', h);
        };
    }

    /**
     * Called each time a setup function of an extension register an extension point
     *
     * @param extensionPointType
     * @param handler
     */
    onExtensionPointSetupRegister<T extends ExtensionComponent>(
        types: string[],
        handler: (event: ExtensionRegisterEvent<T>)=>void,
    ): ()=>void {
        const h = (event: ExtensionRegisterEvent<T>) => {
            handler(event);
            event.setProcessed();
        };

        types.forEach((type) => {
            this.#extensionEventEmitter.on(`onSetupComponent:${type}`, h);
        });

        return () => {
            types.forEach((type) => {
                this.#extensionEventEmitter.off(`onSetupComponent:${type}`, h);
            });
        };
    }

    onExtensionCompletelyLoaded(handler: (extensionRuntime: ExtensionRuntime) => void): (()=>void) {
        this.#extensionEventEmitter.on('onManifestLoaded', handler);

        return () => {
            this.#extensionEventEmitter.off('onManifestLoaded', handler);
        };
    }

    addArgonosExtensionComponentType(type: string, descriptor: ArgonosExtensionComponentTypeDescriptor) {
        this.#argonosExtensionComponentTypes[type] = descriptor;
    }

    getArgonosExtensionComponentType(type: string): ArgonosExtensionComponentTypeDescriptor {
        const descriptor = this.#argonosExtensionComponentTypes[type] || { label: messages.unknownArgonosExtensionComponentType };

        return descriptor;
    }
}
