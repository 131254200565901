import {
    camelCase,
    chain,
    isEmpty,
    isObject,
    isUndefined,
    keyBy,
    merge,
    omit,
    omitBy,
    pickBy,
    reduce,
} from 'lodash';
import Debug from 'debug';
import * as uuid from 'uuid';

import {
    Vertex,
    VertexAttachment,
    VertexId,
    VertexSorting,
    VertexStyle,
} from '../../model/vertex';
import { Edge, EdgeBasicInfo, EdgeStyle } from '../../model/edge';
import {
    PropertyType,
    PropertyValueInfos,
    UniverseId,
    UniverseType,
    UniverseVertexType,
    UniverseVertexTypeName,
    VertexOrEdgeProperties,
    VertexSettings,
} from '../../model/universe';
import { CustomPath, PathParams, PathType } from '../../model/responses';
import {
    Exploration,
    ExplorationOrigin,
    VertexNeighbor,
    VisualizationVertexDetailed,
    VisualizationVertexForm,
} from '../../model/exploration';
import { CaseFlaggedObject, FlaggedVertex } from '../../model/flagged-object';
import { SuggestResult, SuggestVertex, VertexSuggestResult } from '../../model/suggest';
import { MapVisualization, MapVisualizationCluster, MapVisualizationVertex } from '../../model/map-visualization';
import { Change } from '../../model/vertexHistoryChangesResponse';
import { StyleTemplate } from '../../model/style-template';
import {
    ActivityActionContent,
    ActivityType,
    ArgonosPieceActionContent,
    ExplorationActivity,
} from '../../model/exploration-activity';
import {
    RawCaseFlaggedObject,
    RawChange,
    RawEdgeBasicInfo,
    RawExploration,
    RawExplorationActivity,
    RawExplorationGroup,
    RawFlaggedVertex,
    RawGraphClusterNode,
    RawGraphEdgeItem,
    RawGraphVertexNode,
    RawGraphVisualization,
    RawListVisualizationVertex,
    RawMapVisualization,
    RawMapVisualizationClusterNode,
    RawMapVisualizationVertexNode,
    RawPropertyValues,
    RawSearchResult,
    RawSearchSuggestResult,
    RawStyleTemplate,
    RawTableVisualization,
    RawTableVisualizationVertex,
    RawUniverseObjectValue,
    RawVertex,
    RawVertexAttachment,
    RawVertexNeighbor,
    RawVertexOrEdgeProperties,
    RawVisualizationEdgeDetailed,
    RawVisualizationStyleItem,
    RawVisualizationVertex,
    RawVisualizationVertexDetailed,
    RawVisualizationVertexForm,
    SuggestRawVertex,
} from './raws';
import { VertexUtils } from '../vertex-utils';
import { DEFAULT_HEATMAP_SETTINGS } from '../../constants/map';
import {
    EndpointType,
    VisualizationClusterEdge,
    VisualizationEdge,
    VisualizationGroup,
    VisualizationVertex,
} from 'src/exploration/model/exploration-visualization';
import {
    GraphVisualization,
    GraphVisualizationCluster,
    GraphVisualizationClusterEdge,
    ListVisualizationVertex,
    TableVisualization,
    TableVisualizationVertex,
} from '../../model/graph-visualization';
import { ExplorationGroup } from '../../model/group';
import { CasePieceType } from 'src/model/case-piece-type';
import { mapDate, mapScheduleSettings } from 'src/utils/connectors/mappers';
import { CasePiece } from 'src/exploration/model/case-piece';
import { PDF_PREVIEW_METADATA, ResourceCasePiece, ResourceId } from 'src/model/resource';
import { BriefPreview, BriefSection } from '../../model/brief';
import { ExplorationStyleTemplateContent } from '../../model/template';
import { TemplateExported } from '../../../model/template';
import { isFormDisplayTemplate } from 'src/settings/universes/form/utils';
import { FormDisplayTemplate, FormDisplayTemplates } from 'src/exploration/model/form-display-template';
import { ScreenshotImageMetadata } from 'src/exploration/model/resource';
import { Watchlist, WatchlistResult } from '../../watchlists/model/watchlists';

const debug = Debug('argonode:utils:ExplorationConnector:mappers');

export function mapRawVertexToVertex(rawVertex: RawVertex): Vertex {
    const ret: Vertex = {
        id: rawVertex.id,
        type: rawVertex.type,
        title: rawVertex.title,
        style: mapVerticesStyles(rawVertex.style),
        createdDate: mapDate(rawVertex.createdDate),
        lastUpdatedDate: mapDate(rawVertex.lastUpdatedDate),
        properties: mapProperties(rawVertex.properties),
        linkedEdges: rawVertex.linkedEdges?.map(mapEdgeBasicInfo),
    };

    return ret;
}

export function mapRawSuggestVertexToSuggestVertex(rawVertex: SuggestRawVertex): SuggestVertex {
    const ret: SuggestVertex = {
        ...mapRawVertexToVertex(rawVertex),
        flags: rawVertex.flags,
    };

    return ret;
}

export function mapVisualizationEdgeDetailed(rawVisualizationEdgeDetailed: RawVisualizationEdgeDetailed, from?: VertexId, to?: VertexId) {
    const edge: Edge = {
        id: rawVisualizationEdgeDetailed.id,
        title: rawVisualizationEdgeDetailed.title,
        type: rawVisualizationEdgeDetailed.type,
        style: mapEdgesStyles(rawVisualizationEdgeDetailed.style),
        from: from || '',
        to: to || '',
        properties: mapProperties(rawVisualizationEdgeDetailed.properties),
    };

    return edge;
}

export function mapGraphEdgeItemToEdge(rawGraphEdgeItem: RawGraphEdgeItem): VisualizationEdge {
    const edge: VisualizationEdge = {
        id: rawGraphEdgeItem.id || uuid.v4(),
        title: rawGraphEdgeItem.title,
        type: rawGraphEdgeItem.type,
        style: mapEdgesStyles(rawGraphEdgeItem.style),
        from: rawGraphEdgeItem.from.id,
        to: rawGraphEdgeItem.to.id,

        isSelected: rawGraphEdgeItem.isSelected,
    };

    return edge;
}

function mapGraphEdgeItem(rawGraphEdgeItem: RawGraphEdgeItem) {
    const graphEdgeItem: GraphVisualizationClusterEdge = {
        id: rawGraphEdgeItem.id || uuid.v4(),
        from: rawGraphEdgeItem.from,
        to: rawGraphEdgeItem.to,
        count: rawGraphEdgeItem.count,
        title: rawGraphEdgeItem.title,
        type: rawGraphEdgeItem.type,
        style: mapEdgesStyles(rawGraphEdgeItem.style),

        isSelected: rawGraphEdgeItem.isSelected,
    };

    return graphEdgeItem;
}

export function mapEdgeBasicInfo(rawEdgeBasicInfo: RawEdgeBasicInfo) {
    const edgeBasicInfo: EdgeBasicInfo = {
        id: rawEdgeBasicInfo.id,
        type: rawEdgeBasicInfo.type,
        from: rawEdgeBasicInfo.from,
        to: rawEdgeBasicInfo.to,
        properties: mapProperties(rawEdgeBasicInfo.properties),
    };

    return edgeBasicInfo;
}

export function mapAdvancedVerticesStyles(styles?: Record<string, RawVisualizationStyleItem>): VertexStyle | undefined {
    if (!styles) {
        return;
    }

    const constructSize = (styles: Record<string, RawVisualizationStyleItem>): number | undefined => {
        const ssize = styles?.size;
        if (ssize === undefined) {
            return undefined;
        }

        let size = ssize.userDefinedContent?.size;

        const ratio = ssize.gradient;
        const sizeRange = ssize.userDefinedContent?.gradientSize;
        const minSize = ssize.userDefinedContent?.gradientSize?.[0];
        const maxSize = ssize.userDefinedContent?.gradientSize?.[1];

        if (ratio && sizeRange && minSize && maxSize) {
            size = ratio * (maxSize - minSize) + minSize;
        }

        return size;
    };

    const advancedStyles: VertexStyle = {
        //fillColor: styles?.color?.userDefinedContent?.fillColor,
        //strokeColor: styles?.color?.userDefinedContent?.strokeColor,
        iconColor: styles?.color?.userDefinedContent?.iconColor || styles?.color?.userDefinedContent?.fillColor,

        size: constructSize(styles),

        iconName: styles?.icon?.userDefinedContent?.iconName,
        iconFontFamily: styles?.icon?.userDefinedContent?.iconFontFamily,
        iconScale: styles?.icon?.userDefinedContent?.iconScale,
        badgeColor: styles?.badges?.userDefinedContent?.fillColor,
        badgeBlink: styles?.badges?.userDefinedContent?.badgeBlink,
        badgeSize: styles?.badges?.userDefinedContent?.size,
        badgeIcon: styles?.badges?.userDefinedContent?.iconName,
        badgeFontFamily: styles?.badges?.userDefinedContent?.iconFontFamily,
    };

    return advancedStyles;
}

export function mapDefaultVerticesStyles(defaultStyle?: RawVisualizationStyleItem) {
    const userDefinedContent = defaultStyle?.userDefinedContent;
    if (!userDefinedContent) {
        return {};
    }

    const mappedStyle: VertexStyle = {
        //fillColor: userDefinedContent.fillColor,
        //strokeColor: userDefinedContent.strokeColor,
        iconColor: userDefinedContent.iconColor || userDefinedContent.fillColor,

        size: userDefinedContent.size,

        iconName: userDefinedContent.iconName,
        iconFontFamily: userDefinedContent.iconFontFamily,
        iconScale: userDefinedContent.iconScale,

        imageUrl: userDefinedContent.imageUrl,
    };

    return mappedStyle;
}

export function mapVerticesStyles(styles?: Record<string, RawVisualizationStyleItem>): VertexStyle | undefined {
    if (!styles) {
        return;
    }

    const defaultStyles = mapDefaultVerticesStyles(styles?.default);

    const advancedStyles = mapAdvancedVerticesStyles(styles);

    const mergedStyles = merge({}, defaultStyles, advancedStyles);

    const finalStyles = omitBy(mergedStyles, (styleValue) => (styleValue === undefined));

    if (isEmpty(finalStyles)) {
        return undefined;
    }

    return finalStyles;
}

export function mapClusterStyles(styles?: Record<string, RawVisualizationStyleItem>): VertexStyle | undefined {
    if (!styles) {
        return;
    }

    const defaultStyles = mapDefaultVerticesStyles(styles?.default);

    const advancedStyles = mapAdvancedVerticesStyles(styles);

    const mergedStyles = merge({}, omit(defaultStyles, 'size'), advancedStyles);

    const finalStyles = omitBy(mergedStyles, (styleValue) => (styleValue === undefined));

    if (isEmpty(finalStyles)) {
        return undefined;
    }

    return finalStyles;
}

function mapEdgesStyles(styles?: Record<string, RawVisualizationStyleItem>): EdgeStyle | undefined {
    if (!styles) {
        return undefined;
    }
    const defaultUserDefinedContent = styles?.default?.userDefinedContent;

    const defaultStyles: EdgeStyle = {
        color: defaultUserDefinedContent?.fillColor,
        size: defaultUserDefinedContent?.size,
        lineAnimation: defaultUserDefinedContent?.lineAnimation,
        lineStyle: defaultUserDefinedContent?.lineStyle,
    };

    const constructSize = (styles: Record<string, RawVisualizationStyleItem>) => {
        let size = styles?.size?.userDefinedContent?.size;

        const userDefinedContent = styles?.size?.userDefinedContent;

        const ratio = styles?.size?.gradient;
        const sizeRange = userDefinedContent?.gradientSize;
        const minSize = sizeRange?.[0];
        const maxSize = sizeRange?.[1];

        if (ratio && sizeRange && minSize && maxSize) {
            size = ratio * (maxSize - minSize) + minSize;
        }

        return size;
    };

    const advancedStyles: EdgeStyle = {
        color: styles?.color?.userDefinedContent?.iconColor,
        lineStyle: styles?.style?.userDefinedContent?.lineStyle,
        size: constructSize(styles),
    };

    const mergedStyles = merge({}, defaultStyles, advancedStyles);

    const finalStyles = omitBy(mergedStyles, (styleValue) => (styleValue === undefined));

    if (isEmpty(finalStyles)) {
        return undefined;
    }

    return finalStyles;
}

export function mapExplorationInfo(rawExplorationInfo: RawExploration): Exploration {
    const explorationInfo: Exploration = {
        id: rawExplorationInfo.id,
        type: CasePieceType.Exploration,

        displayName: rawExplorationInfo.name,
        universeId: rawExplorationInfo.universeId,

        lastVisitedDate: mapDate(rawExplorationInfo.lastVisitedDate),

        createdBy: rawExplorationInfo.createdBy,
        createdDate: mapDate(rawExplorationInfo.createdDate),

        lastUpdatedBy: rawExplorationInfo.lastUpdatedBy,
        lastUpdatedDate: mapDate(rawExplorationInfo.lastUpdatedDate),

        explorationOrigin: rawExplorationInfo.origin as ExplorationOrigin | undefined,
    };

    return explorationInfo;
}

export function mapExplorationGroup(rawExplorationGroup: RawExplorationGroup): ExplorationGroup {
    const explorationGroup: ExplorationGroup = {
        id: rawExplorationGroup.identifier,
        name: rawExplorationGroup.name,
        filter: rawExplorationGroup.filter,
        userDefinedContent: rawExplorationGroup.userDefinedContent,
    };

    return explorationGroup;
}

export function mapGraphVisualization(result: RawGraphVisualization): GraphVisualization {
    const clusters = chain(result.nodes || [])
        .filter((node) => !!(node as RawGraphClusterNode).cluster)
        .map((node, index) => {
            const rawGraphCluster = (node as RawGraphClusterNode).cluster;
            const explorationGraphClusterItem: GraphVisualizationCluster = {
                id: `${rawGraphCluster.id || index}`,
                count: rawGraphCluster.count,
                selectedCount: rawGraphCluster.selectedCount ?? 0,
                //flaggedCount: rawGraphCluster.flaggedCount ?? 0,
                filter: rawGraphCluster.filter,
                title: rawGraphCluster.title,
                style: mapClusterStyles((node as RawGraphClusterNode).style),
                preselectedCount: rawGraphCluster.preselectedCount,
            };

            return explorationGraphClusterItem;
        })
        .keyBy((node: GraphVisualizationCluster) => node.id)
        .value();

    const groups = chain(result.groups || [])
        .map((rawVisualizationGroup) => {
            const visualizationGroup: VisualizationGroup = {
                ...mapExplorationGroup(rawVisualizationGroup),
                count: rawVisualizationGroup.count,
            };

            return visualizationGroup;
        })
        .keyBy((group) => group.id)
        .value();

    const vertices = chain(result.nodes || [])
        .filter((node) => !!(node as RawGraphVertexNode).vertex?.id)
        .map((node) => {
            const vertexNode = node as RawGraphVertexNode;
            const rawVisualizationsGraphVertex = vertexNode.vertex;
            const visualizationVertex: VisualizationVertex = {
                ...mapVisualizationVertex(rawVisualizationsGraphVertex),
                groupIds: rawVisualizationsGraphVertex.groupIds,
                style: mapVerticesStyles((node as RawGraphVertexNode)?.style),
            };

            return visualizationVertex;
        })
        .keyBy((vertex) => vertex.id)
        .value();

    const edges = chain(result?.edges || [])
        .filter((edge) => !!edge.id && edge.from.type === EndpointType.Vertex && edge.to.type === EndpointType.Vertex)
        .map((edge: RawGraphEdgeItem) => {
            const ret = mapGraphEdgeItemToEdge(edge);

            return ret;
        })
        .keyBy((node: VisualizationEdge) => node.id)
        .value();

    const clusterEdges = chain(result.edges || [])
        .filter((edge) => edge.from.type === EndpointType.Cluster || edge.to.type === EndpointType.Cluster)
        .map((clusterEdge) => {
            return mapGraphEdgeItem(clusterEdge);
        })
        .keyBy((edge: VisualizationClusterEdge) => edge.id)
        .value();

    const ret: GraphVisualization = {
        verticesCount: result.count ?? 0,
        filteredVerticesCount: result.count ?? 0,
        selectedVerticesCount: result.selectedCount ?? 0,
        //edgesCount: result.edgesCount ?? 0,
        selectedEdgesCount: result.selectedEdgesCount ?? 0,
        selectedFlaggedObjectsCount: result.selectedFlaggedObjectsCount ?? 0,
        preselectedCount: result.preselectedCount ?? 0,

        vertices,
        edges,
        clusters,
        clusterEdges,
        groups,
    };

    return ret;
}

/**
 * Lodash keyBy use the last matching element to be responsible for generating the key.
 * keyByFirst use the first element instead
 */
function keyByFirst<T>(collection: T[], iteratee: (value: T) => string | number) {
    const withKeyByFirst = collection.reduce((result, item) => {
        const key = iteratee(item);
        if (!(key in result)) {
            result[key] = item;
        }

        return result;
    }, {} as Record<string | number, T>);

    return withKeyByFirst;
}

export function mapMapVisualization(rawMapVisualization: RawMapVisualization, verticesCount: number, selectedVerticesCount: number) {
    const mapVisualization: MapVisualization = {
        verticesCount,
        selectedVerticesCount: selectedVerticesCount,

        filteredVerticesCount: rawMapVisualization.count,
        //filteredSelectedVerticesCount: rawMapVisualization.selectedCount ?? 0,

        //edgesCount: rawMapVisualization.edgeCount ?? 0,
        selectedEdgesCount: rawMapVisualization.selectedEdgeCount ?? 0,
        selectedFlaggedObjectsCount: rawMapVisualization.selectedFlaggedObjectsCount ?? 0,
        preselectedCount: rawMapVisualization.preselectedCount ?? 0,

        groups: {},

        region: rawMapVisualization.region,
        clusters: chain(rawMapVisualization.nodes || [])
            .filter((node) => !!(node as RawMapVisualizationClusterNode).cluster)
            .map((clusterNode, index) => {
                const rawMapVisualizationCluster = (clusterNode as RawMapVisualizationClusterNode).cluster;
                const mapVisualizationCluster: MapVisualizationCluster = {
                    id: `${rawMapVisualizationCluster.id || index}`,
                    count: rawMapVisualizationCluster.count,
                    selectedCount: rawMapVisualizationCluster.selectedCount ?? 0,
                    //flaggedCount: rawMapVisualizationCluster.flaggedCount ?? 0,
                    coordinates: rawMapVisualizationCluster.coordinates,
                    value: rawMapVisualizationCluster.value,
                    style: mapVerticesStyles(clusterNode.style),
                    filter: rawMapVisualizationCluster.clusterFilter,
                };

                return mapVisualizationCluster;
            })
            .keyBy((cluster) => cluster.id)
            .value(),
        // Node can reference the same vertex if the vertex have multiple geo values
        // Use keyByFirst to keep the first geo value of a vertex instead of the last
        // https://dev.azure.com/chapsvision/Fabrique/_workitems/edit/35503
        vertices: keyByFirst(
            (rawMapVisualization.nodes || [])
                .filter((node) => !!(node as RawMapVisualizationVertexNode).vertex)
                .map((vertexNode) => {
                    const rawMapVisualizationVertex = (vertexNode as RawMapVisualizationVertexNode).vertex;
                    const mapVisualizationVertexObject: MapVisualizationVertex = {
                        ...mapVisualizationVertex(rawMapVisualizationVertex),
                        coordinates: rawMapVisualizationVertex.coordinates,
                        style: mapVerticesStyles(vertexNode.style),
                    };

                    return mapVisualizationVertexObject;
                })
            , (vertex) => vertex.id),
        edges: chain(rawMapVisualization?.edges || [])
            .filter((edge: RawGraphEdgeItem) => !!edge.id && edge.from.type === EndpointType.Vertex && edge.to.type === EndpointType.Vertex)
            .map((edge: RawGraphEdgeItem) => {
                return mapGraphEdgeItemToEdge(edge);
            })
            .keyBy((edge: VisualizationEdge) => edge.id)
            .value(),

        clusterEdges: chain(rawMapVisualization?.edges || [])
            .filter((edge: RawGraphEdgeItem) => edge.from.type === EndpointType.Cluster || edge.to.type === EndpointType.Cluster)
            .map((clusterEdge) => {
                return mapGraphEdgeItem(clusterEdge);
            })
            .keyBy((edge) => edge.id)
            .value(),
    };

    return mapVisualization;
}

export function mapCasePiece(result: any, forceType?: CasePieceType): CasePiece {
    let type = CasePieceType.Unknown;
    let description;
    const id = result.id;
    let customInfos = {};

    switch (forceType || result.type) {
        case 'exploration':
        case CasePieceType.Exploration:
            type = CasePieceType.Exploration;
            customInfos = {
                id: result.content.explorationId,
                universeId: result.content.universeId,
                casePieceId: id,
                explorationOrigin: result.origin || 'unknown',
            };

            break;

        case 'form':
        case CasePieceType.Form:
            type = CasePieceType.Form;
            customInfos = {
                vertexInfo: {
                    id: result.content.vertexId as VertexId,
                    type: result.content.vertexType as UniverseVertexTypeName,
                },
                universeId: result.content.universeId,
            };
            break;

        case 'brief':
        case CasePieceType.Brief: {
            type = CasePieceType.Brief;

            const sections = result.sections.map((rawSection: any): BriefSection => {
                const briefSection: BriefSection = {
                    id: rawSection.id,
                    manifest: {
                        cloudServices: {
                            tokenUrl: '*** will be filled after ***',
                            webSocketUrl: rawSection.editor.manifest.CloudServices.webSocketUrl,
                            uploadUrl: rawSection.editor.manifest.CloudServices.uploadUrl,
                        },
                        collaboration: {
                            channelId: rawSection.editor.manifest.Collaboration.channelId,
                        },
                    },
                    name: rawSection.name,
                    mode: rawSection.mode,
                    locked: rawSection.locked,
                    metaProperties: rawSection.metadata,
                };

                return briefSection;
            });

            customInfos = {
                sections,
                universeId: result.universeId || result.universe?.id,
            };
            break;
        }
        case CasePieceType.FlaggedObject: {
            type = CasePieceType.FlaggedObject;
            customInfos = {
                flag: result,
            };
            break;
        }

        default:
            debug('mapPiece', 'Unknown case piece type:', result);
            break;
    }

    const casePiece: CasePiece = {
        id,
        displayName: result.name,
        type,
        description,

        createdDate: mapDate(result.createdDate),
        createdBy: result.createdBy,
        lastUpdatedDate: mapDate(result.lastUpdatedDate),
        lastUpdatedBy: result.lastUpdatedBy,
        lastVisitedDate: mapDate(result.lastVisitedDate),

        ...customInfos,
    };

    return casePiece;
}

export const formatCasePath = (path: string): string => {
    let startIndex = 0;
    let endIndex = path.length - 1;

    if (path[startIndex] === '/') {
        startIndex++;
    }

    if (path[endIndex] === '/') {
        endIndex--;
    }

    const formattedPath = path.slice(startIndex, endIndex).trim().replace('/', ' / ').replace('  ', ' ');

    return formattedPath;
};


export function dateToISOString(asOf: Date | string | undefined): string | undefined {
    if (asOf instanceof Date) {
        const ret = asOf.toISOString();

        return ret;
    }

    if (asOf !== undefined) {
        const ret = String(asOf.toString());

        return ret;
    }

    return undefined;
}

export function mapSearchResult(result: RawSearchResult): VertexId[] {
    return result.hits.hits.map(hit => hit.vertex.id);
}

export function mapSuggestResult(result: RawSearchSuggestResult): SuggestResult {
    if (!result) {
        return {
            total: 0,
            vertexSuggestResult: [],
        };
    }

    const vertexSuggestResult = result.hits.map((hit) => {
        const vertexSuggestResult: VertexSuggestResult = {
            vertex: mapRawSuggestVertexToSuggestVertex(hit.vertex),
            score: hit.score,
        };

        return vertexSuggestResult;
    });

    return {
        total: result.total,
        vertexSuggestResult,
    };
}

export function mapToPath(paths: CustomPath[]): PathType[] {
    return paths.map((path) => {
        const mappedCustomPath: PathType = {
            id: path.id,
            isDisplayed: path.isDisplayed,
            displayLabel: path.displayLabel,
            parameters: path.parameters.steps.map((step) => {
                const mappedParameter: PathParams = {
                    id: step.id,
                    label: step.label,
                    visibility: step.visibility, //visibilty is an API wording error
                    type: step.type,
                    description: step.description,
                };

                return mappedParameter;
            }),
        };

        return mappedCustomPath;
    });
}

export function mapTableVisualizationVertexValues(rawValues: RawPropertyValues[]): PropertyValueInfos[][] {
    return rawValues.map(propValue => propValue.values.map(value => mapPropertyValues(value, propValue.type)));
}

export function mapProperties(rawProperties: RawVertexOrEdgeProperties): VertexOrEdgeProperties {
    const properties: VertexOrEdgeProperties = {};
    for (const [propName, propValue] of Object.entries(rawProperties)) {
        properties[propName] = propValue.values.map((value) => mapPropertyValues(value, propValue.type));
    }

    return properties;
}

function mapPropertyValues(value: RawUniverseObjectValue, type: PropertyType): PropertyValueInfos {
    const propValueInfos: PropertyValueInfos = {
        value: isUndefined(value.value) ? null : value.value,
        details: value.details,
        meta: value.metadata || {},
        type,
    };

    return propValueInfos;
}

export function mapVertexNeighbors(referentVertexId: VertexId, rawVertexNeighbors?: RawVertexNeighbor[]) {
    return rawVertexNeighbors?.map(rawVertexNeighbor => {
        let fromVertexId: VertexId | undefined;
        let toVertexId: VertexId | undefined;
        if (rawVertexNeighbor.direction === 'Outgoing') {
            fromVertexId = rawVertexNeighbor.neighbor.id;
            toVertexId = referentVertexId;
        } else {
            toVertexId = rawVertexNeighbor.neighbor.id;
            fromVertexId = referentVertexId;
        }

        const vertexNeighbor: VertexNeighbor = {
            direction: rawVertexNeighbor.direction,
            edge: mapVisualizationEdgeDetailed(rawVertexNeighbor.edge, fromVertexId, toVertexId),
            neighbor: mapVisualizationVertexDetailed(rawVertexNeighbor.neighbor),
        };

        return vertexNeighbor;
    });
}

export function mapVisualizationVertexForm(rawVisualizationVertexForm: RawVisualizationVertexForm, universeId: UniverseId) {
    const displayTemplate: FormDisplayTemplate | undefined = isFormDisplayTemplate(rawVisualizationVertexForm.displayTemplate)
        ? rawVisualizationVertexForm.displayTemplate : undefined;

    const ret: VisualizationVertexForm = {
        ...mapVisualizationVertexDetailed(rawVisualizationVertexForm),
        attachments: rawVisualizationVertexForm?.attachments?.map((attachment) => {
            return mapVertexAttachment(attachment, universeId);
        }),
        neighbors: mapVertexNeighbors(rawVisualizationVertexForm.id, rawVisualizationVertexForm.neighbors),
        displayTemplate,
    };

    return ret;
}

export function mapVisualizationVertexDetailed(rawVisualizationVertexDetailed: RawVisualizationVertexDetailed) {
    const ret: VisualizationVertexDetailed = {
        ...mapVisualizationVertex(rawVisualizationVertexDetailed),
        properties: mapProperties(rawVisualizationVertexDetailed.properties),
    };

    return ret;
}

export function mapTableVisualizationVertex(rawTableVisualizationVertex: RawTableVisualizationVertex) {
    const ret: TableVisualizationVertex = {
        ...mapVisualizationVertex(rawTableVisualizationVertex),
        values: mapTableVisualizationVertexValues(rawTableVisualizationVertex.values),
        subTables: rawTableVisualizationVertex.subTables?.map(t => mapTableVisualization(t)),
        attachments: rawTableVisualizationVertex.attachments,
    };

    return ret;
}

export function mapListVisualizationVertex(rawListVisualizationVertex: RawListVisualizationVertex) {
    const listVisualizationVertex: ListVisualizationVertex = {
        ...mapVisualizationVertex(rawListVisualizationVertex),
        properties: rawListVisualizationVertex.properties ? mapProperties(rawListVisualizationVertex.properties) : undefined,
        summary: rawListVisualizationVertex.summary,
    };

    return listVisualizationVertex;
}


export function mapVisualizationVertex(rawVisualizationVertex: RawVisualizationVertex) {
    const visualizationVertex: VisualizationVertex = {
        id: rawVisualizationVertex.id,
        title: rawVisualizationVertex.title,
        isSelected: rawVisualizationVertex.isSelected,
        type: rawVisualizationVertex.type,
        style: mapVerticesStyles(rawVisualizationVertex.style),
        flag: rawVisualizationVertex.flag,
        score: rawVisualizationVertex.score,
        isExtracted: rawVisualizationVertex.isExtracted,
        isPreselected: rawVisualizationVertex.isPreselected,
    };

    return visualizationVertex;
}

function mapFlaggedVertex(rawFlaggedVertex?: RawFlaggedVertex): FlaggedVertex | undefined {
    if (!rawFlaggedVertex) {
        return undefined;
    }

    const flaggedVertex: FlaggedVertex = {
        ...mapVisualizationVertexDetailed(rawFlaggedVertex),
        universeId: rawFlaggedVertex.universeId,
    };

    return flaggedVertex;
}

export function mapCaseFlaggedObject(rawCaseFlaggedObject: RawCaseFlaggedObject) {
    const caseFlaggedObject: CaseFlaggedObject = {
        flaggedBy: rawCaseFlaggedObject.flaggedBy,
        created: mapDate(rawCaseFlaggedObject.created)!,
        comment: rawCaseFlaggedObject.comment,
        label: rawCaseFlaggedObject.label,
        vertex: mapFlaggedVertex(rawCaseFlaggedObject.vertex),
    };

    return caseFlaggedObject;
}

export function mapVertexAttachment(rawVertexAttachment: RawVertexAttachment, universeId: UniverseId) {
    let resourceId: ResourceId | undefined = rawVertexAttachment.resourceId;
    if (!resourceId && rawVertexAttachment.url) {
        const r = /^resource:(.*)$/.exec(rawVertexAttachment.url);
        if (r) {
            resourceId = r[1];
        }
    }

    if (!resourceId) {
        throw new Error(`URL not supported: ${rawVertexAttachment.url}`);
    }

    const vertexAttachment: VertexAttachment = {
        type: CasePieceType.Resource,
        contentLength: rawVertexAttachment.contentLength,
        contentType: rawVertexAttachment.contentType,
        displayName: rawVertexAttachment.fileName,
        vertexId: rawVertexAttachment.vertexId,
        metadata: rawVertexAttachment.metadata,
        previews: {
            ...rawVertexAttachment.previews,
            pdf: rawVertexAttachment.metadata?.[PDF_PREVIEW_METADATA],
        },
        uploadDate: mapDate(rawVertexAttachment.uploadDate),
        id: resourceId,
        url: rawVertexAttachment.url,
        universeId,
    };

    return vertexAttachment;
}

export function mapScreenshot(resource: ResourceCasePiece): ScreenshotImageMetadata {
    const metadata = resource.metadata || {};

    const screenshotMetadata: ScreenshotImageMetadata = {};
    if (typeof (metadata.width) === 'string' && metadata.width) {
        screenshotMetadata.width = parseInt(metadata.width);
    }
    if (typeof (metadata.height) === 'string' && metadata.height) {
        screenshotMetadata.height = parseInt(metadata.height);
    }
    if (metadata.explorationId) {
        screenshotMetadata.explorationId = metadata.explorationId;
    }
    if (metadata['universe-id']) {
        screenshotMetadata.universeId = metadata['universe-id'];
    }
    if (metadata.layoutInformation) {
        screenshotMetadata.explorationLayout = JSON.parse(metadata.layoutInformation);
    }

    return screenshotMetadata;
}

export function mapMetadata(object: any): Record<string, any> | undefined {
    if (!isObject(object)) {
        return undefined;
    }

    const metadata = reduce(
        object,
        (acc, value, key) => {
            const newKey = camelCase(key);

            acc[newKey] = value;

            return acc;
        },
        {} as Record<string, any>,
    );

    return metadata;
}

export function mapVertexChange(rawChange: RawChange) {
    const change: Change = {
        comment: rawChange.comment,
        date: mapDate(rawChange.date)!,
        token: rawChange.token,
        userInfo: rawChange.userInfo,
        vertexChanges: rawChange.vertexChanges,
        edgeChanges: rawChange.edgeChanges,
    };

    return change;
}

export function mapStyleTemplate(rawStyleTemplate: RawStyleTemplate) {
    const template: StyleTemplate = {
        id: rawStyleTemplate.id,
        name: rawStyleTemplate.name,
        vertexStyles: rawStyleTemplate.vertexStyles,
        edgeStyles: rawStyleTemplate.edgeStyles,
        createdBy: rawStyleTemplate.createdBy,
        lastUpdatedBy: rawStyleTemplate.lastUpdatedBy,
        isSettingsTemplate: rawStyleTemplate.isDefault,
        createdDate: mapDate(rawStyleTemplate.createdDate)!,
        lastUpdatedDate: mapDate(rawStyleTemplate.lastUpdatedDate),
    };

    return template;
}

export function mapExplorationStyleTemplateExported(explorationStyleTemplateExported: TemplateExported, isSettingsTemplate: boolean) {
    const styles = JSON.parse(explorationStyleTemplateExported.content) as ExplorationStyleTemplateContent;
    const template: StyleTemplate = {
        name: explorationStyleTemplateExported.name,
        id: explorationStyleTemplateExported.id,
        createdBy: explorationStyleTemplateExported.createdBy,
        createdDate: explorationStyleTemplateExported.createdDate,
        lastUpdatedBy: explorationStyleTemplateExported.lastUpdatedBy,
        lastUpdatedDate: explorationStyleTemplateExported.lastUpdatedDate,
        vertexStyles: styles.vertexStyles || {},
        edgeStyles: styles.edgeStyles || {},
        isSettingsTemplate: isSettingsTemplate,
    };

    return template;
}

const getActivityType = (actionContent?: ActivityActionContent) => {
    if (actionContent?.dataType === ActivityType.ArgonosPiece) {
        return (actionContent as ArgonosPieceActionContent).entityType as ActivityType;
    }

    return actionContent?.dataType;
};

export function mapExplorationEntry(rawExplorationActivity: RawExplorationActivity) {
    const explorationActivity: ExplorationActivity = {
        id: rawExplorationActivity.id,
        identity: rawExplorationActivity.identity,
        token: rawExplorationActivity.token,
        date: mapDate(rawExplorationActivity.date)!,
        type: getActivityType(rawExplorationActivity.action.content) || ActivityType.Exploration,
        action: rawExplorationActivity.action,
        effects: rawExplorationActivity.effects,
    };

    return explorationActivity;
}

// Add formatted settings (sorting & style) to the universe schema edges & vertices and reorder their properties following order
export function formatUniverseWithSettings(universe: UniverseType): UniverseType {
    const edgesSettings = formatSettingsStyleAndSorting(universe.settings.edges);
    const verticesSettings = formatSettingsStyleAndSorting(universe.settings.vertices);

    const edges = universe.schema.edges.map((edge) => {
        const [style, sorting] = edgesSettings[edge.name];

        return {
            ...edge,
            properties: reorderProperties(edge, sorting),
            style,
            sorting,
        };
    });

    const vertices = universe.schema.vertices.map((vertice) => {
        const [style, sorting] = verticesSettings[vertice.name];

        return {
            ...vertice,
            properties: reorderProperties(vertice, sorting),
            style,
            sorting,
        };
    });

    return {
        ...universe,
        schema: {
            ...universe.schema,
            edges,
            vertices,
        },
        settings: {
            ...universe.settings,
            heatmap: universe.settings?.heatmap ?? DEFAULT_HEATMAP_SETTINGS,
        },
    };
}

function formatSettingsStyleAndSorting(elements: Record<string, VertexSettings>) {
    const defaultStyle = elements['_all']?.style || {};
    const defaultVertexStyle = mapVerticesStyles({ default: defaultStyle });

    const defaultSorting = elements['_all']?.sorting || {};

    const mappedSettingsElements = Object.entries(elements).reduce((acc, [key, element]) => {
        const style = isEmpty(element.style)
            ? defaultVertexStyle
            : mapVerticesStyles({ default: element.style });

        const sorting = {
            orderBy: element.sorting?.orderBy ?? defaultSorting.orderBy,
            properties: keyBy(element.sorting?.properties ?? [], (prop) => prop.name),
        };

        acc[key] = [style, sorting];

        return acc;
    }, {} as Record<string, [VertexStyle | undefined, VertexSorting | undefined]>);

    return mappedSettingsElements;
}

function reorderProperties(vertexSchema: UniverseVertexType, sorting: VertexSorting | undefined) {
    if (!sorting) {
        return vertexSchema.properties;
    }

    const sorted = [...vertexSchema.properties].sort((a, b) => (
        VertexUtils.VertexPropertiesSortingCompare(a.name, b.name, sorting, vertexSchema)
    ));

    return sorted;
}

export function mapTableVisualization(raw: RawTableVisualization, verticesCount?: number): TableVisualization {
    const verticesArray = raw.vertices.map(mapTableVisualizationVertex);
    const vertices = keyBy(verticesArray, (vertex) => vertex.id);

    const ret: TableVisualization = {
        verticesCount: verticesCount ?? raw.totalCount!,
        mainVerticesCount: raw.totalCount!,
        filteredVerticesCount: raw.count,
        selectedVerticesCount: raw.selectedCount ?? 0,
        searchCount: raw.searchCount,
        skip: raw.skip,
        columns: raw.columns,
        sorting: raw.sorting,
        vertices,
        verticesArray,
        clusterEdges: {},
        clusters: {},
        edges: {},
        groups: {},
        //        edgesCount: 0,
        selectedEdgesCount: 0,
        selectedFlaggedObjectsCount: raw.selectedFlaggedObjectsCount ?? 0,
        preselectedCount: 0,
    };

    return ret;
}


export function mapBriefPreview(raw: any): BriefPreview {
    const ret: BriefPreview = {
        ...raw,
        createdDate: mapDate(raw.createdDate),
        lastUpdatedDate: mapDate(raw.lastUpdatedDate),
    };

    return ret;
}

export function mapFormDisplayTemplates(raw: any): FormDisplayTemplates {
    const formDisplayTemplates: Record<string, FormDisplayTemplate> = pickBy(raw.displayTemplates ?? {}, (template: any) => {
        if (isFormDisplayTemplate(template)) {
            return true;
        }
        console.error('Invalid form display template', template);

        return false;
    });

    return {
        ...raw,
        displayTemplates: formDisplayTemplates,
    };
}

export function mapWatchlist(watchlist: any): Watchlist {
    const ret: Watchlist = {
        ...watchlist,
        creationDate: mapDate(watchlist.creationDate),
        updatedDate: mapDate(watchlist.updatedDate),
        scheduleSettings: watchlist.scheduleSettings && mapScheduleSettings(watchlist.scheduleSettings),
    };

    return ret;
}

export function mapWatchlistResult(watchlist: any): WatchlistResult {
    const ret: WatchlistResult = {
        ...watchlist,
        date: mapDate(watchlist.creationDate),
        actionDate: mapDate(watchlist.lastActionDate),
    };

    return ret;
}
