import { defineMessages } from 'react-intl';

import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { registerToolItem, Tool } from 'src/components/basic';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from 'src/settings/envrionment-contexts/context-names';
import { SettingsEnvironmentContext } from 'src/settings/envrionment-contexts/settings-environment-context';

export const REFERENCE_TABLES_PATH = `${SETTINGS_ROOT_URL}/reference-tables`;

const messages = defineMessages({
    referenceTablesNavItemLabel: {
        id: 'settings.users.menu.referenceTables',
        defaultMessage: 'Reference tables',
    },
});


export function setupReferenceTables() {
    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'global/reference-tables',
        label: messages.referenceTablesNavItemLabel,
        order: 300,
        icon: 'icon-reference-table',
        visible: (settingsEnvironmentContext: SettingsEnvironmentContext) => {
            // TODO: Add permissions

            return true;
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            const url = REFERENCE_TABLES_PATH;
            environmentContext.navigate(url);
        },
    });
}
