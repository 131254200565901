import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { ArgTable2Column, useClassNames, highlightSplit, useMemoAsync, useArgNotifications, useCallbackAsync } from 'src/components/basic';
import { PageHeader } from 'src/settings/common-components/page-header';
import { OverviewSection } from 'src/components/features/overview/overview-section';
import { dateSorter, stringSorter } from 'src/utils/sorter';
import { DateByUser } from 'src/components/common/date-by-user';
import { BaseReferenceTable } from 'src/settings/models/reference-tables';
import { useArgModalManager } from 'src/components/basic/arg-modal/use-arg-modal-manager';
import { CreateReferenceTableModal } from '../components/create-reference-table-modal';
import { useGetReferenceTables } from 'src/settings/reference-tables/hooks/use-get-reference-tables';

import './reference-tables-view.less';


const CLASSNAME = 'settings-reference-tables-view';
const messages = defineMessages({
    pageTitle: {
        id: 'settings.reference-tables.reference-tables-view.PageTitle',
        defaultMessage: 'Reference tables',
    },
    nameColumnTitle: {
        id: 'settings.reference-tables.reference-tables-view.NameColumnTitle',
        defaultMessage: 'Name',
    },
    descriptionColumnTitle: {
        id: 'settings.reference-tables.reference-tables-view.DescriptionColumnTitle',
        defaultMessage: 'Description',
    },
    creationColumnTitle: {
        id: 'settings.reference-tables.reference-tables-view.CreationColumnTitle',
        defaultMessage: 'Creation',
    },
    lastModificationColumnTitle: {
        id: 'settings.reference-tables.reference-tables-view.LastModificationColumnTitle',
        defaultMessage: 'Last Modification',
    },

    referenceTableCreateButtonLabel: {
        id: 'settings.reference-tables.reference-tables-view.ReferenceTableCreateButtonLabel',
        defaultMessage: 'Create',
    },
    noReferenceTablesMessage: {
        id: 'settings.reference-tables.reference-tables-view.NoReferenceTablesMessage',
        defaultMessage: 'No reference table',
    },
    loadingReferenceTablesMessage: {
        id: 'settings.reference-tables.reference-tables-view.LoadingReferenceTablesMessage',
        defaultMessage: 'Loading reference tables...',
    },
});


export function ReferenceTablesView() {
    const classNames = useClassNames(CLASSNAME);

    const getSearchLabel = (extensionColumns: BaseReferenceTable) => {
        const searchLabel = `${extensionColumns.name} ${extensionColumns.description}`;

        return searchLabel;
    };

    const { referenceTables, updateReferenceTables, referenceTablesProgressMonitor } = useGetReferenceTables();

    const columns = useMemo<Record<string, ArgTable2Column<BaseReferenceTable>>>(() => ({
        name: {
            key: 'name',
            title: messages.nameColumnTitle,
            dataIndex: 'name',
            sorter: (a: BaseReferenceTable, b: BaseReferenceTable) => (
                stringSorter<BaseReferenceTable>(a, b, (item) => item.name)
            ),
            ellipsis: true,
            render: function Name(_data, referenceTable, _idx, search) {
                return <span>{highlightSplit(referenceTable.name, search)}</span>;
            },
        },
        description: {
            key: 'description',
            title: messages.descriptionColumnTitle,
            dataIndex: 'description',
            sorter: (a: BaseReferenceTable, b: BaseReferenceTable) => (
                stringSorter<BaseReferenceTable>(a, b, (item) => item.description)
            ),
            ellipsis: true,
            render: function Description(_data, referenceTable, _idx, search) {
                return <span>{highlightSplit(referenceTable.description || '', search)}</span>;
            },
        },
        creation: {
            key: 'creation',
            title: messages.creationColumnTitle,
            dataIndex: 'createdDate',
            sorter: (a: BaseReferenceTable, b: BaseReferenceTable) => (
                dateSorter<BaseReferenceTable>(a, b, (item) => item.createdDate)
            ),
            render: (lastUpdatedDate, referenceTable) => {
                if (!lastUpdatedDate) {
                    return undefined;
                }

                return <DateByUser date={lastUpdatedDate} user={referenceTable.lastUpdatedBy} />;
            },
        },
        lastModification: {
            key: 'lastUpdatedDate',
            title: messages.lastModificationColumnTitle,
            dataIndex: 'lastUpdatedDate',
            sorter: (a: BaseReferenceTable, b: BaseReferenceTable) => (
                dateSorter<BaseReferenceTable>(a, b, (item) => item.lastUpdatedDate)
            ),
            render: function LastModification(lastModification, referenceTable) {
                if (!lastModification) {
                    return undefined;
                }

                return <DateByUser date={lastModification} user={referenceTable.lastUpdatedBy} />;
            },
        },
    }), []);

    const createReferenceTableModal = useArgModalManager((close) => <CreateReferenceTableModal onClose={close} onCreate={() => updateReferenceTables()} />);

    return (
        <div className={classNames('&')}>
            <PageHeader
                title={messages.pageTitle}
            />
            <OverviewSection<BaseReferenceTable>
                rowKey='name'
                loadingData={referenceTablesProgressMonitor?.isRunning}
                hideTitle={true}
                emptyMessage={messages.noReferenceTablesMessage}
                loadingMessage={messages.loadingReferenceTablesMessage}
                columns={columns}
                allowCreate={true}
                searchType='highlight'
                items={referenceTables}
                getItemLabel={getSearchLabel}
                className={classNames('&-body')}
                onPressCreate={createReferenceTableModal.open}
                createButtonLabel={messages.referenceTableCreateButtonLabel}
            />
        </div>
    );
}
