import { ArgUserInfo } from 'src/components/basic';
import { ReferenceTable } from 'src/settings/models/reference-tables';
import { mapDate } from 'src/utils/connectors/mappers';

export interface RawReferenceTable {
	id: string;
	name: string;
	description?: string;

	createdDate: string;
    createdBy: ArgUserInfo;
    lastUpdatedDate: string;
    lastUpdatedBy: ArgUserInfo;
	items?: ReferenceTable['items'];
}

export const mapReferenceTable = (rawReferenceTable: RawReferenceTable): ReferenceTable => {
    return {
        ...rawReferenceTable,
        createdDate: mapDate(rawReferenceTable.createdDate),
        lastUpdatedDate: mapDate(rawReferenceTable.lastUpdatedDate),
    };
};
