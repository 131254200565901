import { useCallback, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import {
    ArgButton,
    ArgFormLabel,
    ArgInputSearch,
    ArgInputText,
    ArgInputTextArea,
    ArgMessageRenderer,
    ArgModal,
    ArgTable2,
    ArgTable2Column,
    useClassNames,
} from 'src/components/basic';
import { Item } from 'src/settings/models/reference-tables';
import { useCreateReferenceTable } from 'src/settings/reference-tables/hooks/use-create-reference-table';
import { stringSorter } from 'src/utils/sorter';

import './create-reference-table-modal.less';

const CLASSNAME = 'settings-create-reference-table-modal';
const messages = defineMessages({
    referenceTableCreationModalTitle: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalTitle',
        defaultMessage: 'Create a reference table',
    },
    referenceTableCreationModalOkText: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalOkText',
        defaultMessage: 'Create',
    },
    referenceTableCreationModalNameFieldLabel: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalNameFieldLabel',
        defaultMessage: 'Reference table name',
    },
    referenceTableCreationModalNameFieldDescription: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalNameFieldDescription',
        defaultMessage: 'Reference table name must be unique',
    },
    referenceTableCreationModalDescriptionLabel: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalDescriptionLabel',
        defaultMessage: 'Reference table description',
    },
    referenceTableCreationModalValuesListLabel: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalValuesListLabel',
        defaultMessage: 'Values list',
    },
    referenceTableCreationModalSelectAll: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalSelectAll',
        defaultMessage: 'Select all',
    },
    referenceTableCreationModalUnselectAll: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalUnselectAll',
        defaultMessage: 'Unselect all',
    },
    referenceTableEmptyRowsMessage: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableEmptyRowsMessage',
        defaultMessage: 'Click the "(+) Row" button below to insert a new row',
    },
    referenceTableErrorsTitle: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableErrorsTitle',
        defaultMessage: 'Please correct the following errors:',
    },
    referenceTableCreationModalAddRowButtonLabel: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalAddRowButtonLabel',
        defaultMessage: 'Row',
    },
    referenceTableCreationModalKeyColumnLabel: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalKeyColumnLabel',
        defaultMessage: 'Key',
    },
    referenceTableCreationModalValueColumnLabel: {
        id: 'settings.reference-tables.create-reference-table-modal.ReferenceTableCreationModalValueColumnLabel',
        defaultMessage: 'Value',
    },
});


interface CreateReferenceTableModalProps {
    onClose: () => void;
    onCreate?: () => void;
}

export function CreateReferenceTableModal(props: CreateReferenceTableModalProps) {
    const classNames = useClassNames(CLASSNAME);
    const { onClose, onCreate } = props;

    const onCreateReferenceTable = useCallback(() => {
        onCreate?.();
        onClose?.();
    }, [onClose, onCreate]);

    const {
        tableName,
        setTableName,
        tableDescription,
        setTableDescription,
        rows,
        addRow,
        updateRow,
        deleteRow,
        createReferenceTable,
        createReferenceTableProgressMonitor,
        isValid,
        errors,
    } = useCreateReferenceTable({ onCreate: onCreateReferenceTable });
    const [searchTerm, setSearchTerm] = useState('');

    const columns = useMemo<ArgTable2Column<Item>[]>(() => {
        return [
            {
                title: 'Code',
                dataIndex: 'key',
                width: '30%',
                minWidth: '30%',
                key: 'key',
                sorter: (a: Item, b: Item) => (
                    stringSorter<Item>(a, b, (item) => item.key)
                ),
                headerClassName: classNames('&-key-header'),
                getCellStringValue: row => row.key,
                render: (text: string, item: Item, index) => (
                    <ArgInputText
                        className={classNames('&-column')}
                        value={text}
                        onChange={value => updateRow(index!, 'key', value || '')}
                    />
                ),
            },
            {
                title: 'Libellé',
                dataIndex: 'value',
                width: '65%',
                minWidth: '65%',
                headerClassName: classNames('&-value-header'),
                getCellStringValue: row => row.value,
                key: 'value',
                sorter: (a: Item, b: Item) => (
                    stringSorter<Item>(a, b, (item) => item.value)
                ),
                render: (text: string, item: Item, index) => (
                    <ArgInputText
                        className={classNames('&-column')}
                        value={text}
                        onChange={value => updateRow(index!, 'value', value || '')}
                    />
                ),
            },
            {
                title: '',
                dataIndex: '',
                key: 'actions',
                render: (text: string, item: Item, index) => (
                    <ArgButton
                        icon='icon-trash'
                        type='ghost'
                        className='cursor-pointer text-gray-500 hover:text-red-500'
                        onClick={() => deleteRow(index!)}
                    />
                ),
            },
        ];
    }, [classNames, deleteRow, updateRow]);

    const okButtonTooltip = useMemo(() => (errors.length > 0 ? <ErrorTooltip errors={errors} /> : undefined), [errors]);


    return (
        <ArgModal
            size='large'
            progressMonitor={createReferenceTableProgressMonitor}
            title={messages.referenceTableCreationModalTitle}
            okText={messages.referenceTableCreationModalOkText}
            okDisabled={!isValid}
            okButtonTooltip={okButtonTooltip}
            onCancel={onClose}
            onClose={onClose}
            onOk={createReferenceTable}
            className={classNames('&')}
        >
            <div className={classNames('&-content')}>
                <div className={classNames('&-content-header')}>
                    <ArgFormLabel
                        className={classNames('&-name-field')}
                        required={true}
                        propertyName={messages.referenceTableCreationModalNameFieldLabel}
                        size='medium'
                    >
                        <ArgInputText
                            autoFocus={true}
                            value={tableName}
                            onChange={value => setTableName(value || '')}
                            placeholder={messages.referenceTableCreationModalNameFieldLabel}
                        />
                        <ArgMessageRenderer
                            size='small'
                            className={classNames('&-name-field-description')}
                            message={messages.referenceTableCreationModalNameFieldDescription}
                        />
                    </ArgFormLabel>
                    <ArgFormLabel
                        className={classNames('&-description')}
                        propertyName={messages.referenceTableCreationModalDescriptionLabel}
                        size='medium'
                    >
                        <ArgInputTextArea
                            rows={4}
                            size='small'
                            value={tableDescription}
                            onChange={value => setTableDescription(value || '')}
                            placeholder={messages.referenceTableCreationModalDescriptionLabel}
                        />
                    </ArgFormLabel>
                </div>
                <div className={classNames('&-list-container')}>
                    <ArgMessageRenderer
                        size='medium'
                        className={classNames('&-list-label')}
                        message={messages.referenceTableCreationModalValuesListLabel}
                    />
                    <div className={classNames('&-list-controls')}>
                        <ArgInputSearch
                            className={classNames('&-list-controls-search')}
                            value={searchTerm}
                            onChange={value => setSearchTerm(value || '')}
                        />
                    </div>

                    {/* TODO: Implement selection logic buttons
                    <div className={classNames('&-list-controls-selection-tool')}>
                        <ArgButton
                            type='link'
                            size='small'
                            label={messages.referenceTableCreationModalSelectAll}
                            onClick={() => {  }}
                        />
                        <div className={classNames('separator')}>
                            {'|'}
                        </div>
                        <ArgButton
                            type='link'
                            size='small'
                            label={messages.referenceTableCreationModalUnselectAll}
                            onClick={() => {}}
                        />
                    </div>
                    */}
                    <div className={classNames('&-body')}>
                        <ArgTable2<Item>
                            className={classNames('&-body-table', 'scrollable-body')}
                            bordered={true}
                            search={searchTerm}
                            columns={columns}
                            dataSource={rows}
                            emptyRenderer={() => (
                                <ArgMessageRenderer className={classNames('&-add-row-message')} message={messages.referenceTableEmptyRowsMessage} />
                            )}
                        />
                        <div>
                            <ArgButton
                                className={classNames('&-add-row-button')}
                                onClick={addRow}
                                type='link'
                                icon='icon-plus'
                                label={messages.referenceTableCreationModalAddRowButtonLabel}
                                size='medium'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ArgModal>
    );
}

const ERROR_TOOLTIP_CLASSNAME = `${CLASSNAME}-error-tooltip`;
function ErrorTooltip({ errors }: {errors: MessageDescriptor[]}) {
    const classNames = useClassNames(ERROR_TOOLTIP_CLASSNAME);

    return <div className={classNames('&')}>
        <h4 className={classNames('&-header')}>
            <ArgMessageRenderer message={messages.referenceTableErrorsTitle} />
        </h4>
        <ul className={classNames('&-content')}>
            {errors.map(error => (
                <li className={classNames('&-item')} key={error.id}>
                    <ArgMessageRenderer message={error} />
                </li>
            ))}
        </ul>
    </div>;
}
