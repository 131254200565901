import L from 'leaflet';

import { GraphVisualization } from '../model/graph-visualization';
import { UniverseHeatmapSettings } from '../model/universe';

export const DRAWING_COLOR = '#39939E';

export const PATH_OPTIONS: L.PathOptions = {
    color: DRAWING_COLOR,
    fillColor: DRAWING_COLOR,
    opacity: 1,
};

export const POLYGON_OPTIONS: L.PolylineOptions = {
    ...PATH_OPTIONS,
    weight: 2,
    interactive: false,
};

export const POLYGON_MARKER_OPTIONS = L.divIcon({
    className: 'map-widget__polygon-marker',
    iconSize: [10, 10],
    html: '<i></i>',
});

export const TO_REMOVE_POLYGON_OPTIONS: L.PolylineOptions = {
    ...POLYGON_OPTIONS,
    opacity: 0.25,
};

export const EMPTY_GRAPH: GraphVisualization = {
    vertices: {},
    edges: {},
    clusters: {},
    clusterEdges: {},
    groups: {},
    verticesCount: 0,
    filteredVerticesCount: 0,
    selectedVerticesCount: 0,
    //edgesCount: 0,
    selectedEdgesCount: 0,
    selectedFlaggedObjectsCount: 0,
    preselectedCount: 0,
};

export const DEFAULT_HEATMAP_SETTINGS = {
    gradients: [
        ['RoyalBlue', 'SpringGreen', 'Red'],
        ['DeepPink', 'OrangeRed', 'Indigo'],
        ['PowderBlue', 'DarkTurquoise', 'MediumBlue'],
    ],
    radius: 50,
    opacity: 50,
} as UniverseHeatmapSettings;

export const MAP_OPTIONS = {
    worldBounds: {
        displayed: {
            southWestCorner: {
                latitude: -90,
                longitude: -360,
            },
            northEastCorner: {
                latitude: 90,
                longitude: 360,
            },
        },
        real: {
            southWestCorner: {
                latitude: -90,
                longitude: -180,
            },
            northEastCorner: {
                latitude: 90,
                longitude: 180,
            },
        },
    },
    heatmap: {
        viewportPadding: 0.15,
        zoomInThresholdLevel: 12,
        zoomInIntensity: 0.75,
        zoomOutThresholdLevel: 2,
        minIntensity: 0.1,
        maxIntensityFactor: 250,
        heatmapLayerDefaultOptions: {
            radius: DEFAULT_HEATMAP_SETTINGS.radius,
            blur: DEFAULT_HEATMAP_SETTINGS.opacity,
            maxZoom: 9,
            gradient: {
                0.4: DEFAULT_HEATMAP_SETTINGS.gradients[0][0],
                0.65: DEFAULT_HEATMAP_SETTINGS.gradients[0][1],
                1: DEFAULT_HEATMAP_SETTINGS.gradients[0][2],
            },
        },
    },
};
