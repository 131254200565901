import { FormEvent, ReactNode, useCallback } from 'react';
import { Modal, ModalProps } from 'antd';

import { messages } from '../../common/modal2/naming-modal/naming-modal';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-modal.less';

export type ArgModalSize = 'small' | 'medium' | 'large' | 'xlarge' | '2xlarge' | 'fullsize';

const SIZES_VALUES = {
    small: {
        width: 500,
    },
    medium: {
        width: 600,
    },
    large: { width: 870 },
    xlarge: { width: 1300 },
    '2xlarge': { width: 1700 },
    fullsize: {
        width: '100%',
        height: '100%',
    },

};

export interface ArgModalProps extends Omit<ModalProps, 'title' | 'okText' | 'cancelText' | 'className' | 'okButtonProps' | 'cancelButtonProps' | 'onOk' | 'onCancel' | 'destroyOnClose'> {
    /**
     * Classname for the modal component
    */
    className?: ClassValue;
    /**
     * The text that appears in modal top-left corner
     */
    title?: ArgRenderedText;
    /**
     * A props for i18n value
    */
    messageValues?: ArgMessageValues;
    /**
     * Custom confirm text value
    */
    okText?: ArgRenderedText | false;
    /**
     * Custom cancel text value
    */
    cancelText?: ArgRenderedText | false;
    /**
     * Ok button tooltip text value
    */
    okButtonTooltip?: ArgRenderedText;
    okDataTestId?: string;
    /**
     * Cancel button tooltip text value
    */
    cancelButtonTooltip?: ArgRenderedText;
    cancelDataTestId?: string;
    /**
     * Child element (modal content)
    */
    children?: ReactNode;
    /**
     * A custom size from a predefined set for the modal
    */
    size?: ArgModalSize;
    /**
     * A loader for the ok button
    */
    loading?: boolean;
    progressMonitor?: ProgressMonitor;
    /**
     * Disable the ok button
    */
    okDisabled?: boolean;
    /**
     *  Disable the cancel button
    */
    cancelDisabled?: boolean;
    /**
     * Callback when clicking on cross in top-right corner
    */
    onClose?: () => void;
    /**
     * Hide modal
    */
    visible?: boolean;
    /**
     * Callback when clicking on confirmation button
    */
    onOk?: (event: ButtonClickEvent | undefined) => void;
    /**
     * Callback when clicking on cancel button
    */
    onCancel?: (event: ButtonClickEvent | undefined) => void;
    /**
     * Callback when clicking on confirmation button
    */
    onModalSubmit?: (event: FormEvent) => void;
}
/**
 * This component is designed to display a modal.
 *
 * @example
 * ```
 * <ArgModal children={<p>a p children</p>} title='Title modal' />
 * ```
 */
export function ArgModal(props: ArgModalProps) {
    const {
        className,
        wrapClassName,
        title,
        children,
        messageValues,
        cancelText,
        progressMonitor,
        okDisabled,
        okText,
        okButtonTooltip,
        cancelButtonTooltip,
        onCancel,
        onClose,
        onOk,
        loading,
        footer,
        okDataTestId,
        cancelDataTestId,
        visible = true,
        cancelDisabled,
        size,
        onModalSubmit,
        ...otherProps
    } = props;

    const classNames = useClassNames('arg-modal');

    const handleOk = useCallback((event?: ButtonClickEvent) => {
        onOk?.(event);
    }, [onOk]);

    const handleCancel = useCallback((event: ButtonClickEvent) => {
        if (onCancel) {
            onCancel?.(event);

            return;
        }
        onClose?.();
    }, [onCancel, onClose]);

    const _title: ReactNode = renderText(title, messageValues);

    const _loading = loading || progressMonitor?.isRunning;

    const wrapCls = {
        loading: _loading,
    };

    const handleModalSubmit = useCallback((event: FormEvent) => {
        if (event.defaultPrevented || !onModalSubmit) {
            return;
        }

        event.preventDefault();

        onModalSubmit(event);
    }, [onModalSubmit]);

    const handleModalRender = useCallback((node: ReactNode):ReactNode => {
        return <form className={classNames('&-form', 'ant-modal-content')} onSubmit={handleModalSubmit}>
            {props.modalRender ? props.modalRender(node) : node}
        </form>;
    }, [classNames, handleModalSubmit, props.modalRender]);

    let _footer = footer;
    if (_footer !== false && !_footer) {
        _footer = <div className={classNames('&-footer')}>
            {cancelText !== false && <ArgButton
                className={classNames('&-footer-btn')}
                type='secondary'
                onClick={handleCancel}
                label={cancelText || messages.cancel}
                messageValues={messageValues}
                tooltip={cancelButtonTooltip}
                data-testid={cancelDataTestId}
                disabled={cancelDisabled}
            />}
            {okText !== false && <ArgButton
                className={classNames('&-footer-btn')}
                type='primary'
                htmlType='submit'
                onClick={handleOk}
                label={okText || messages.ok}
                loading={_loading}
                disabled={okDisabled || _loading}
                messageValues={messageValues}
                tooltip={okButtonTooltip}
                data-testid={okDataTestId}
            />}
        </div>;
    }

    return (
        <Modal
            className={classNames('&', className)}
            wrapClassName={classNames('&-wrap', wrapClassName, wrapCls)}
            {...SIZES_VALUES[size || 'small']}
            maskClosable={false}
            destroyOnClose={true}
            {...otherProps}
            open={visible}
            centered={true}
            closeIcon={<ArgIcon name='icon-cross' />}
            title={<span className={classNames('&-header-title')}> {_title}</span>}
            onCancel={onClose}
            footer={_footer}
            modalRender={onModalSubmit ? handleModalRender : props.modalRender}
        >
            {children}
        </Modal>
    );
}
